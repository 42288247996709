import { createContext } from 'react';

import type { TResultsFilter } from '@/modules/results/model/types';

const initialFilter: TResultsFilter = {
  sport: 0,
  tournament: 0,
  date: 0,
  lang: '',
};

type ResultsLayoutContext = {
  filter: TResultsFilter;
};

const initValue: ResultsLayoutContext = {
  filter: initialFilter,
};

export const ResultsLayoutContext = createContext<ResultsLayoutContext>(initValue);
