import amaticLogo from '@assets/provider-logos/amatic.svg';
import apolloLogo from '@assets/provider-logos/apollo.svg';
import aviatrixLogo from '@assets/provider-logos/aviatrix.svg';
import betsoftLogo from '@assets/provider-logos/betsoft.svg';
import cq9Logo from '@assets/provider-logos/cq9.svg';
import elbetLogo from '@assets/provider-logos/elbet.svg';
import evoplayLogo from '@assets/provider-logos/evoplay.svg';
import hacksawLogo from '@assets/provider-logos/hacksaw.svg';
import igrosoftLogo from '@assets/provider-logos/igrosoft.svg';
import wmgLogo from '@assets/provider-logos/logo_wmg.png';
import netentLogo from '@assets/provider-logos/netent.svg';
import nolimitLogo from '@assets/provider-logos/nolimit.svg';
import playsonLogo from '@assets/provider-logos/playson.svg';
import playtechLogo from '@assets/provider-logos/playtech.svg';
import quickspinLogo from '@assets/provider-logos/quickspin.svg';
import relaxLogo from '@assets/provider-logos/relax.svg';
import smartsoftLogo from '@assets/provider-logos/smartsoft.svg';
import spadegamingLogo from '@assets/provider-logos/spadegaming.svg';
import spribeLogo from '@assets/provider-logos/spribe.svg';
import wazdanLogo from '@assets/provider-logos/wazdan.svg';
// import pragmaticLogo from '@assets/provider-logos/pragmatic-logo.svg';
import pragmaticLogo2 from '@assets/provider-logos/pragmatic.svg';
import tvbetLogo from '@assets/provider-logos/tvbet.svg';
import betgamesLogo from '@assets/provider-logos/betgames.svg';
import mascotLogo from '@assets/provider-logos/mascot.svg';
import microgamingLogo from '@assets/provider-logos/microgaming.svg';
import inbetgamesLogo from '@assets/provider-logos/inbetgames.svg';
import endorphinaLogo from '@assets/provider-logos/endorphina.svg';
import fivemenLogo from '@assets/provider-logos/fivemen.png';
import elcasinoLogo from '@assets/provider-logos/el-casino-icon.svg';
import twainsportLogo from '@assets/provider-logos/twain-icon.png';
import agtLogo from '@assets/provider-logos/agt.svg';
import fastsportLogo from '@assets/provider-logos/fastsport-icon.png';
import digitainLogo from '@assets/provider-logos/digitain-icon.png';
import defaultLogo from '@assets/provider-logos/default-provider.svg?url';


const labelIcons: Record<string, string> = {
  default: defaultLogo,
  amatic: amaticLogo,
  apollo: apolloLogo,
  aviatrix: aviatrixLogo,
  betsoft: betsoftLogo,
  cqgaming: cq9Logo,
  elbet: elbetLogo,
  evoplay: evoplayLogo,
  hacksaw: hacksawLogo,
  'hacksaw gaming': hacksawLogo,
  igrosoft: igrosoftLogo,
  netent: netentLogo,
  nolimit: nolimitLogo,
  playson: playsonLogo,
  playtech: playtechLogo,
  quickspin: quickspinLogo,
  relaxgaming: relaxLogo,
  ssg: smartsoftLogo,
  smartsoft: smartsoftLogo,
  spribe: spribeLogo,
  spadegaming: spadegamingLogo,
  wazdan: wazdanLogo,
  wmg: wmgLogo,
  pragmatic: pragmaticLogo2,
  tvbet: tvbetLogo,
  betgames: betgamesLogo,
  mascot: mascotLogo,
  microgaming: microgamingLogo,
  fivemen: fivemenLogo,
  '5men': fivemenLogo,
  inbetgames: inbetgamesLogo,
  endorphina: endorphinaLogo,
  elcasino: elcasinoLogo,
  'el casino': elcasinoLogo,
  agt: agtLogo,
  fastsport: fastsportLogo,
  digitain: digitainLogo,
  'twain sport': twainsportLogo,
};

type IconProviderProps = {
  className?: string;
  provider: string;
}

export const getProviderIconUrl = (provider: string | undefined) =>
  labelIcons[(provider ?? '').toLocaleLowerCase()] ?? labelIcons.default;

const IconProvider = ({ className, provider }: IconProviderProps) => {
  const icon = labelIcons[provider.toLocaleLowerCase()] ?? labelIcons.default;

  return (
    icon
      ?
      <img className={className} src={icon} alt="" />
      : null
  );
};

export default IconProvider;
