import ResultsFilterContext from '../../model/results-filter-context';
import ResultsSportsDesktop from './results-sports-desktop';

import './results-filter-desktop.scss';

import type { Dayjs } from 'dayjs';
import type { TResultsFilter } from '../../model/types';

type ResultsFilterProps = {
  filter: TResultsFilter;
  onFilterChange: (newFilter: Partial<TResultsFilter>) => void;
  onDateChange?: (date: Dayjs | 0) => void;
}

const ResultsFilterDesktop = ({ filter, onFilterChange, onDateChange }: ResultsFilterProps) => (
  <ResultsFilterContext.Provider value={{ filter, onFilterChange, onDateChange }}>
    <ResultsSportsDesktop />
  </ResultsFilterContext.Provider>
);

export default ResultsFilterDesktop;
