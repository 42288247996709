import { baseApi } from '../../../shared/store/api';
import { adapteFavoriteGames, adapteFavoritesMatches, adaptePromocodes, adapteTransactions, adapteUserData, adapteUserSettingsToClient, adapteUserSettingsToServer, adapteUserWallets, adapteWithdrawals } from './adapter';
import { CasinoBonusItem, CasinoBonusItemServer, GetPromocodesParams, GetTransactionsParams, GetWithdrawalsParams, IAddGameToFavoriteParams, GetUpdateUserParams, IAddMatchToFavoriteParams, IFavoriteGame, IFavoriteMatch, InitPaymentParams, InitWithdrawalParams, IpApiResponse, IPromocodes, Transactions, UserData, UserGeo, UserSettings, Wallet, Withdrawals, UploadKycParams } from './types';


const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserGeo: build.query<UserGeo, void>({
      query: () => 'https://ipapi.co/json/',
      transformResponse: (res: IpApiResponse) => ({
        country: res.country || null,
        countryName: res.country_name || null,
        countryPhoneCode: res.country_calling_code || null,
        currency: res.currency || null,
      })
    }),

    checkAuth: build.query<UserData, void>({
      query: () => 'v1/user/me',
      transformResponse: adapteUserData,
      providesTags: ['Auth']
    }),

    getUserWallets: build.query<Wallet[], void>({
      query: () => 'v1/user/accounts',
      transformResponse: adapteUserWallets,
      providesTags: ['Auth', 'wallets']
    }),

    verifyTelegram: build.query<string, void>({
      query: () => 'v1/user/verify/telegram'
    }),

    updateUser: build.mutation<string, GetUpdateUserParams>({
      query: (params) => ({
        url: 'v1/user',
        method: 'PUT',
        body: {
          'phone': params.phone,
          'telegram_nickname': params.telegramNickname,
        },
      }),
      invalidatesTags: ['Auth']
    }),

    updateWallet: build.mutation<string, string>({
      query: (currency: string) => ({
        url: 'v1/user/accounts',
        method: 'PUT',
        params: {
          'currency': currency
        }
      }),
      invalidatesTags: ['Auth', 'wallets']
    }),

    createWallet: build.mutation<string, string>({
      query: (currency: string) => ({
        url: 'v1/user/accounts',
        method: 'POST',
        params: {
          'currency': currency
        }
      }),
      invalidatesTags: ['Auth', 'wallets']
    }),

    getTransactions: build.query<Transactions, GetTransactionsParams>({
      query: ({ pageSize = 50, page = 1, ascending = false, type = '' }) => ({
        url: `v1/user/transactions${type && (`/${type}`)}`,
        params: {
          'page_size': pageSize,
          'page': page,
          'ascending': ascending,
        }
      }),
      transformResponse: adapteTransactions
    }),

    getWithdrawals: build.query<Withdrawals, GetWithdrawalsParams>({
      query: ({ pageSize = 50, page = 1, ascending = false, }) => ({
        url: 'v1/user/withdrawals',
        params: {
          'page_size': pageSize,
          'page': page,
          'ascending': ascending,
        }
      }),
      transformResponse: adapteWithdrawals,
      providesTags: ['withdrawals']
    }),

    initWithdrawal: build.mutation<unknown, InitWithdrawalParams>({
      query: (body) => ({
        url: 'v1/user/withdrawals/init',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['withdrawals', 'wallets'],
    }),

    cancelWithdrawal: build.mutation<unknown, string>({
      query: (withdrawalId) => ({
        url: 'v1/user/withdrawals/cancel',
        method: 'POST',
        params: {
          'withdrawal_id': withdrawalId,
        }
      }),
      invalidatesTags: ['withdrawals'],
    }),

    initPayment: build.mutation<string, InitPaymentParams>({
      query: ({ systemName, ...body }) => ({
        url: `v1/payment/${systemName}/init`,
        method: 'POST',
        body,
      }),
      transformResponse: (res: { payment_url: string }) => res.payment_url
    }),

    getPromocodes: build.query<IPromocodes, GetPromocodesParams>({
      query: ({ page, pageSize, ascending }) => ({
        url: 'v1/user/promocodes',
        params: {
          page,
          'page_size': pageSize,
          ascending,
        }
      }),
      transformResponse: adaptePromocodes,
    }),

    activatePromocode: build.mutation<unknown, string>({
      query: (promocode) => ({
        url: 'v1/user/promocode',
        method: 'POST',
        params: { promocode }
      })
    }),

    getUserSettings: build.query<UserSettings, void>({
      query: () => '/v1/user/settings',
      transformResponse: adapteUserSettingsToClient,
      providesTags: ['Auth', 'settings'],
    }),

    updateUserSettings: build.mutation<UserSettings, UserSettings>({
      query: (params) => ({
        url: '/v1/user/settings',
        method: 'PUT',
        body: adapteUserSettingsToServer(params)
      }),
      transformResponse: adapteUserSettingsToClient,
      invalidatesTags: ['settings'],
    }),

    getFavoritesGames: build.query<IFavoriteGame[], void>(({
      query: () => 'v1/user/favorites/casino_games',
      transformResponse: adapteFavoriteGames,
      providesTags: ['favorite-games', 'Auth'],
    })),

    addGameToFavorite: build.mutation<unknown, IAddGameToFavoriteParams>({
      query: ({ gameId, gameName, provider, mainProvider }) => ({
        url: 'v1/user/favorites/casino_game',
        method: 'POST',
        body: {
          'game_id': gameId,
          'game_name': gameName,
          'provider': provider,
          'main_provider': mainProvider
        }
      }),
      invalidatesTags: ['favorite-games']
    }),

    deleteFavoriteGame: build.mutation<unknown, string>({
      query: (id) => ({
        url: 'v1/user/favorites/casino_game',
        method: 'DELETE',
        params: {
          id
        }
      }),
      invalidatesTags: ['favorite-games']
    }),

    getFavoriteMatches: build.query<IFavoriteMatch[], void>({
      query: () => 'v1/user/favorites/matches',
      transformResponse: adapteFavoritesMatches,
      providesTags: ['favorite-matches', 'Auth']
    }),

    addMatchToFavorite: build.mutation<unknown, IAddMatchToFavoriteParams>({
      query: (params) => ({
        url: 'v1/user/favorites/match',
        method: 'POST',
        body: {
          'match_id': params.matchId,
          'team_1': params.team1,
          'team_2': params.team2,
          'sport_name': params.sportName,
          'sport_id': params.sportId,
          'tournament_name': params.tournamentName,
          'tournament_id': params.tournamentId,
          'country_id': params.countryId,
          'country_name': params.countryName,
          'data_type': params.dataType,
        }
      }),
      invalidatesTags: ['favorite-matches']
    }),

    deleteFavoriteMatch: build.mutation<unknown, number>({
      query: (id) => ({
        url: 'v1/user/favorites/match',
        method: 'DELETE',
        params: {
          id
        }
      }),
      invalidatesTags: ['favorite-matches']
    }),

    uploadKyc: build.mutation<unknown, UploadKycParams>({
      query: ({ method, body }) => ({
        url: 'v1/user/kyc/upload',
        method: 'POST',
        params: {
          method
        },
        body,
      })
    }),

    getCasinoBonuses: build.query<CasinoBonusItem[], void>({
      query: () => 'v1/user/casino_bonuses',
      transformResponse: (res: CasinoBonusItemServer[]) =>
        res.map((item) => ({
          currency: item.currency,
          provider: item.provider,
          bonusId: item.bonus_id,
          fsAmount: item.fs_amount,
          wager: item.wager,
        })),
      providesTags: ['Auth', 'wallets']
    })
  })
});

export const {
  useGetUserGeoQuery,
  useCheckAuthQuery,
  useVerifyTelegramQuery,
  useUpdateUserMutation,
  useGetUserWalletsQuery,
  useUpdateWalletMutation,
  useCreateWalletMutation,
  useGetPromocodesQuery,
  useActivatePromocodeMutation,
  useGetTransactionsQuery,
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
  useGetWithdrawalsQuery,
  useCancelWithdrawalMutation,
  useInitWithdrawalMutation,
  useInitPaymentMutation,
  useGetFavoritesGamesQuery,
  useAddGameToFavoriteMutation,
  useDeleteFavoriteGameMutation,
  useGetFavoriteMatchesQuery,
  useAddMatchToFavoriteMutation,
  useDeleteFavoriteMatchMutation,
  useUploadKycMutation,
  useGetCasinoBonusesQuery,
} = userApi;

export default userApi;
