import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useTelegramChange } from '../../hooks/useTelegramChange';
import TelegramVerifyBtn from './telegram-verify-btn';
import { TextFieldControlled } from '@/shared/components/text-field/text-field';
// import Button from '@/shared/components/button/button';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import LoaderCenter from '@/shared/components/loader-center/loader-center';

import './telegram-change.scss';

const b = bemCn('telegram-change');

type TelegramChangeProps = {
  className?: string;
  inverted?: boolean;
}

const TelegramChange = ({ className, inverted }: TelegramChangeProps) => {
  const { t } = useTranslation();
  const {
    userMainData,
    telegramValue,
    isLoading,
    control,
    errors,
    isEdit,
    setIsEdit,
    handleSubmit,
    handleFormSubmit,
  } = useTelegramChange();

  return (
    <form className={b({ inverted }, className)}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <h2 className={b('title')}>
        <span>Telegram</span>
        {userMainData?.telegramVerified && (
          <div className={b('icon')}>
            <IconSvg className={b('icon-check')} name="check" />
          </div>
        )}
      </h2>

      {isLoading && <LoaderCenter className={b('loading')} />}

      {errors.root && (
        <p className={b('error')}>{errors.root.message}</p>
      )}

      {!isEdit && (
        <p className={b({ inverted }, b('field-string'))}>
          <span>{userMainData?.telegramNickname}</span>
          <button className={b('btn-pen')}
            type='button'
            onClick={() => setIsEdit(true)}
          >
            <IconSvg className={b('icon-edit')} name="edit" />
          </button>
        </p>
      )}

      {isEdit && (
        <div className={b('field-wrapper')}>
          <TextFieldControlled className={b('field')}
            control={control}
            type='text'
            inverted={inverted}
            id='telegramNickname'
            name='telegramNickname'
            placeholder={t('settings.enter-telegram', 'Введите ваш Telegram')}
            disabled={isLoading}
          />
          <div className={b('btns')}>
            <button className={b('btn-edit')}
              type='submit'
              disabled={(telegramValue === userMainData?.telegramNickname) || isLoading}
            >
              <IconSvg className={b('icon-edit-check')} name="check" />
            </button>
            <button className={b('btn-edit')}
              type='button'
              onClick={() => setIsEdit(false)}
            >
              <IconSvg className={b('icon-edit-close')} name="close" />
            </button>
          </div>
        </div>
      )}

      <div className={b('btns')}>
        {/* {!userMainData?.telegramNickname && (
          <Button className={b('btn')}
            type='submit'
            variant="bordered"
            disabled={(telegramValue === userMainData?.telegramNickname) || isLoading}
          >
            {t('settings.add-telegram', 'Добавить Telegram')}
          </Button>
        )} */}

        {(userMainData?.telegramNickname && !userMainData?.telegramVerified && !isEdit) && <TelegramVerifyBtn />}
      </div>

    </form>
  );
};

export default TelegramChange;
