import { useTranslation } from 'react-i18next';
import { useGetPartnerProjectPlayersQuery, useGetPartnerProjectStatsQuery } from '@/modules/partner-program/api/partnerApi';
import { PartnerProject } from '@/modules/partner-program/model/types';
import ButtonIcon, { IButtonIconProps } from '@/shared/components/button-icon/button-icon';
import { useState } from 'react';
import PartnerStatsDrawer from '../../core/partner-stats-drawer/partner-stats-drawer';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { usePPFilter } from '@/modules/partner-program/hooks/usePPFilter';
import { usePPPagination } from '@/modules/partner-program/hooks/usePPPagination';
import { useAppSelector } from '@/shared/hooks';
import { selectPPSelectedCurrency } from '@/modules/partner-program/model/selectors';

type ProjectStatsBtnProps = IButtonIconProps & {
  project: PartnerProject;
}

const ProjectStatsBtn = ({ className, project }: ProjectStatsBtnProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [opened, setOpened] = useState(false);

  const currency = useAppSelector(selectPPSelectedCurrency);

  const initParams = {
    projectId: `${project.id}`,
  };

  const {
    filterParams,
    handleFilterChange,
  } = usePPFilter(initParams);

  const {
    params: paginationParams,
    handlePageChange,
  } = usePPPagination();

  const {
    data: promocodeStatsData,
    isLoading: isStatsLoading
  } = useGetPartnerProjectStatsQuery({ ...filterParams, currency }, { skip: !opened });

  const {
    data: promocodePlayers,
    isLoading: isPlayersLoading
  } = useGetPartnerProjectPlayersQuery({ ...filterParams, ...paginationParams, currency }, { skip: !opened });
  const totalPlayersCount = promocodePlayers?.count ?? 0;

  return (
    <>
      <ButtonIcon
        className={className}
        icon='stats'
        variant='bordered'
        onClick={() => setOpened(true)}
      />
      <PartnerStatsDrawer
        title={t('project-stats', 'Статистика проекта')}
        isOpen={opened}
        onClose={() => setOpened(false)}
        metaInfo={
          <>
            <p>{t('title', 'Название')}: {project.name}</p>
            <p>{t('created', 'Создан')}: {dayjsCustom(project.created).format('DD.MM.YYYY')}</p>
          </>
        }
        stats={promocodeStatsData}
        players={promocodePlayers?.items}
        isStatsLoading={isStatsLoading}
        isPlayersLoading={isPlayersLoading}
        totalPlayersCount={totalPlayersCount}
        paginationParams={paginationParams}
        handleFilterChange={handleFilterChange}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default ProjectStatsBtn;
