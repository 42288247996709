import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthorizationStatus, NameSpace } from '../../../shared/utils/constants/common';
import authApi from './auth-api';
import userApi from '../../user/store/user-api';
import { dropToken, saveToken } from '../utils/token';
import { AuthState, AuthModal } from './types';
import { dropRefCode } from '../utils/ref-code';

const initialState: AuthState = {
  authorizationStatus: AuthorizationStatus.Unknown,
  activeModal: null,
  passwordResetId: null,
};

const authSlice = createSlice({
  name: NameSpace.Auth,
  initialState,
  reducers: {
    changeAuthModal: (state, action: PayloadAction<AuthModal>) => {
      state.activeModal = action.payload;
    },
    closeAuthModal: (state) => {
      state.activeModal = null;
    },
    openLoginModal: (state) => {
      state.activeModal = 'login';
    },
    openRegisterModal: (state) => {
      state.activeModal = 'registration';
    },
    openPasswordChangeModal: (state, action: PayloadAction<string>) => {
      state.passwordResetId = action.payload;
      state.activeModal = 'password-change';
    },
    logout: (state) => {
      state.authorizationStatus = AuthorizationStatus.NoAuth;
      dropToken();
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state) => {
        state.authorizationStatus = AuthorizationStatus.Auth;
        if (state.activeModal === 'login') {
          state.activeModal = null;
        }
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth;
      })

      .addMatcher(authApi.endpoints.registerByEmail.matchFulfilled, (state, action) => {
        const isVerification = action.payload.verification;

        if (isVerification) {
          state.activeModal = 'email-confirm';
        } else {
          state.activeModal = 'registration-success';
        }
      })
      .addMatcher(authApi.endpoints.registerByPhone.matchFulfilled, (state, action) => {
        const isVerification = action.payload.verification;

        if (isVerification) {
          // TODO: Сделать верификацию для регистрации по телефону
        } else {
          state.activeModal = 'registration-success';
        }
      })
      .addMatcher(authApi.endpoints.registerByTelegram.matchFulfilled, (state, action) => {
        const vereficationUrl = action.payload;
        if (vereficationUrl) {
          window.open(vereficationUrl, '_blank');
        }
        state.activeModal = 'registration-success';
      })

      .addMatcher(authApi.endpoints.verifyEmail.matchRejected, (state) => {
        state.activeModal = 'verify-error';
      })
      .addMatcher(authApi.endpoints.verifyEmail.matchFulfilled, (state, action) => {
        state.activeModal = 'registration-success';
        const token = action.payload;

        if (token) {
          saveToken(token);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .addMatcher(authApi.endpoints.verifyPhone.matchFulfilled, (state, action) => {
        state.activeModal = 'registration-success';
        const token = action.payload;

        if (token) {
          saveToken(token);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })

      .addMatcher(authApi.endpoints.changePassword.matchFulfilled, (state) => {
        state.activeModal = 'password-reset-success';
      })

      .addMatcher(authApi.endpoints.resetPassword.matchRejected, (state) => {
        state.activeModal = 'password-reset-error';
      })

      .addMatcher(userApi.endpoints.checkAuth.matchFulfilled, (state) => {
        state.authorizationStatus = AuthorizationStatus.Auth;
      })
      .addMatcher(userApi.endpoints.checkAuth.matchRejected, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth;
        dropToken();
      })
      .addMatcher(authApi.endpoints.clickRefCode.matchRejected, () => {
        dropRefCode();
      });
  },
});

export const {
  logout,
  openPasswordChangeModal,
} = authSlice.actions;

export default authSlice;
