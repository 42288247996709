import { DEFAULT_LANG } from '@/app-config';
import { useCreateTVBetSessionQuery } from '@/modules/casino';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const serverUrl = 'https://tvbetframe13.com';

type StartGameParams = {
  lng?: string;
  clientId?: string;
  token?: string;
  server?: string;
  containerId?: string;
  gameId?: string;
}

const startGame = ({
  lng,
  clientId,
  token,
  server,
  containerId = 'tvbet-container',
  gameId,
}: StartGameParams) => {
  // @ts-ignore
  new TvbetFrame({
    lng,
    clientId,
    token,
    server,
    containerId,
    'game_id': gameId,
  });
};

const loadScript = (onLoad: () => void) => {
  const tvbetScript = document.getElementById('tvbet-script');

  if (!tvbetScript) {
    const script = document.createElement('script');

    script.onload = onLoad;

    script.type = 'text/javascript';
    script.id = 'tvbet-script';
    script.src = `${serverUrl}/assets/frame.js`;

    document.head.appendChild(script);
  } else {
    onLoad();
  }
};

const removeScript = () => {
  document.getElementById('tvbet-script')?.remove();
};

const Tvbet = () => {
  const { i18n } = useTranslation();
  const { data: appStatuses } = useGetStatusQuery();
  const isTvbetActive = appStatuses?.tvbet ?? false;
  const { gameId } = useParams();
  const { data } = useCreateTVBetSessionQuery(
    undefined,
    { skip: !isTvbetActive }
  );

  useEffect(() => {
    if (isTvbetActive && data?.token && data?.clientId) {
      loadScript(() => startGame({
        lng: i18n.resolvedLanguage ?? DEFAULT_LANG,
        clientId: data.clientId,
        token: data.token,
        server: serverUrl,
        containerId: 'tvbet-container',
        gameId,
      }));
    }

    return removeScript;
  }, [data]);

  if (!isTvbetActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <div>
      <div id="tvbet-container"></div>
    </div>
  );
};

export default Tvbet;
