import { useEffect } from 'react';
import { useCreateDigitainSessionQuery } from '@/modules/casino';
import i18n from '@/shared/i18n/i18n';
import GameScreen from '../game-screen';
import { useParams } from 'react-router-dom';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@/shared/hooks/use-responsive';

import { AppFeature, DEFAULT_LANG } from '@/app-config';

const Digitain = () => {
  const { t } = useTranslation();
  // const { data: appStatuses } = useGetStatusQuery();
  // const isDigitainActive = appStatuses?.digitain ?? false;
  const isDigitainActive = AppFeature.DIGITAIN_GAMES;
  const { gameId = '' } = useParams();
  const { isDesktop } = useResponsive();

  const { data: sessionUrl, isLoading, isError } = useCreateDigitainSessionQuery({
    gameId: Number(gameId),
    playMode: 'real',
    deviceType: isDesktop ? 1 : 2,
    lang: i18n.resolvedLanguage ?? DEFAULT_LANG,
    mainDomain: `${window.location.origin}/games?category=all-providers&provider=digitain`,
    cashierUrl: `${window.location.origin}/deposit`,
  }, { skip: !isDigitainActive });

  useEffect(() => {
    window.addEventListener('message', (event: MessageEvent<{ type: string; mainDomain: string }>) => {
      if (event.data.type === 'rgs-backToHome') {
        window.location.href = event.data.mainDomain;
      }
    });
  }, []);

  if (!isDigitainActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <GameScreen
      title="Digitain"
      src={sessionUrl}
      loading={isLoading}
      isError={isError}
      errorMessage={t('common.unknown-error', 'Неизвестная ошибка')}
    />
  );
};

export default Digitain;
