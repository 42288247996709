import { Outlet } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import { useResultsFilter } from '../../hooks/use-results-filter';
import { ResultsLayoutContext } from '../../model/results-layout-context';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import Container from '@shared/components/container/container';
import ResultsFilterDesktop from '../results-filter-desktop/results-filter-desktop';

import './results-layout-desktop.scss';

const b = bemCn('results-layout-desktop');

const ResultsLayoutDesktop = () => {
  useScrollToTop();
  const {
    filter,
    handleFilterChange,
    handleDateChange,
  } = useResultsFilter();

  return (
    <ResultsLayoutContext.Provider value={{ filter }}>
      <div className={b()}>
        <Container className={b('wrapper')}>
          <Breadcrumbs className={b('breadcrumbs')} />

          <div className={b('layout')}>
            <div className={b('left-column')}>
              <ResultsFilterDesktop
                filter={filter}
                onFilterChange={handleFilterChange}
                onDateChange={handleDateChange}
              />
            </div>

            <div className={b('content')}>
              <Outlet />
            </div>
          </div>
        </Container>
      </div>
    </ResultsLayoutContext.Provider>
  );
};

export default ResultsLayoutDesktop;
