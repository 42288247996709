import { LegacyRef, ReactNode } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg, { AvailableIcon } from '../icon-svg/icon-svg';

const b = bemCn('custom-datepicker');

type DatePickerBtnProps = {
  value?: ReactNode;
  isHideValue?: boolean;
  onClick?: (evt: React.MouseEvent) => void;
  className?: string;
  variant?: 'primary' | 'secondary' | 'bordered' | 'text';
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  icon?: AvailableIcon;
  leftIcon?: AvailableIcon;
  rightIcon?: AvailableIcon;
  ariaLabel?: string;
  disabled?: boolean;
};

const DatePickerBtn = (props: DatePickerBtnProps, ref: LegacyRef<HTMLButtonElement>) => {
  const {
    value,
    isHideValue,
    onClick,
    className,
    variant = 'primary',
    loading = false,
    type = 'button',
    icon,
    leftIcon,
    rightIcon,
    ariaLabel,
    disabled,
  } = props;

  const buttonClasses = b({
    primary: variant === 'primary',
    secondary: variant === 'secondary',
    bordered: variant === 'bordered',
    text: variant === 'text',
    loading,
    disabled
  }, className);

  const handleClick = (evt: React.MouseEvent) => {
    if (loading || disabled) {
      evt.preventDefault();
    } else {
      onClick?.(evt);
    }
  };

  return (
    <button className={buttonClasses}
      type={type}
      onClick={handleClick}
      aria-label={ariaLabel}
      disabled={loading || disabled}
      ref={ref}
    >
      {leftIcon && <IconSvg className={b('icon', { left: true })} name={leftIcon} />}
      {isHideValue ? null : value}
      {icon && <IconSvg className={b('icon')} name={icon} />}
      {rightIcon && <IconSvg className={b('icon', { right: true })} name={rightIcon} />}
    </button>
  );
};

export default DatePickerBtn;
