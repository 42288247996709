import { bemCn } from '@shared/utils/helpers/bem-cn';
import IconSvg from '@shared/components/icon-svg/icon-svg';

import './results-score.scss';

const b = bemCn('results-score');
const pattern = 'Result';

type TResultJsonItem = {
  P: string;
};

type TResultJson = {
  [pattern]: TResultJsonItem[];
};

type Props = {
  score: string;
  variant?: 'game' | 'subgame';
};

const ResultsScore = (props: Props) => {
  const {
    score,
    variant = 'subgame',
  } = props;

  const textClassName = variant === 'game' ? 'text-header' : 'text-item';
  let modifiedScore = score;

  if (score && score.includes(pattern)) {
    const jsonObj: TResultJson = JSON.parse(score) as TResultJson;
    modifiedScore = jsonObj[pattern].map((item) => item.P).join('\n');
  }

  return (
    <div
      className={b()}
      onClick={() => navigator.clipboard.writeText(modifiedScore)}
    >
      <IconSvg className={b('copy-icon')} name="copy" />
      <span className={b('text', b(textClassName))}>{modifiedScore}</span>
    </div>
  );
};

export default ResultsScore;
