import { FieldValues, useController, UseControllerProps, useFormContext } from 'react-hook-form';


import FileInput, { FileInputProps } from '../file-input/file-input';


type CameraInputProps<T extends FieldValues> = FileInputProps & UseControllerProps<T> & {
  mediaType: 'IMAGE' | 'VIDEO';
}

function CameraInput<T extends FieldValues>(props: CameraInputProps<T>) {
  const { mediaType, disabled, ...inputProps } = props;
  const { control } = useFormContext<T>();
  const { field, fieldState } = useController({ ...props, control });

  return (
    <FileInput
      {...inputProps}
      label={mediaType === 'VIDEO' ? 'Сделать видео' : 'Сделать фото'}
      capture="environment"
      name={field.name}
      accept={
        mediaType === 'VIDEO'
          ? 'video/mp4, video/webm'
          : 'image/jpeg, image/png'
      }
      onChange={field.onChange}
      onBlur={field.onBlur}
      status={fieldState.invalid ? 'error' : undefined}
      error={fieldState.error?.message}
      disabled={field.disabled || disabled}
    />
  );
}

export default CameraInput;
