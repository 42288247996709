import { baseApi } from '@shared/store/api';
import { adapteAppSports, adapteBanners, adapteKYCMethods, adapteStatus, adapteTupupMethods, adapteWithdrawalMethods } from './adapter';
import {
  AppStatus,
  ITopupMethodsByCurrency,
  TBanner,
  WithdrawalMethodsByCurrency,
  BanksList,
  BanksListRes,
  AppSports,
  KYCMethod,
} from './types';

const appApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStatus: build.query<AppStatus, void>({
      query: () => ({
        url: 'v1/status',
        timeout: 30000,
      }),
      transformResponse: adapteStatus
    }),

    getCurrencies: build.query<string[], void>({
      query: () => 'v1/currencies',
    }),

    getBanksList: build.query<BanksList, void>({
      query: () => '/v1/banks',
      transformResponse: (res: BanksListRes) =>
        res.dictionary.map((item) => ({ ...item, id: item.schema, name: item.bankName }))
    }),

    getWithdrawalMethods: build.query<WithdrawalMethodsByCurrency, void>({
      query: () => 'v1/withdrawal_methods',
      transformResponse: adapteWithdrawalMethods
    }),

    getPaymentsMethods: build.query<ITopupMethodsByCurrency, void>({
      query: () => 'v1/user/payment_systems',
      transformResponse: adapteTupupMethods,
      providesTags: ['Auth'],
    }),

    // TODO: Добавить типизацию
    getBetLimits: build.query({
      query: () => 'v1/bet_limits'
    }),

    // TODO: Добавить типизацию
    getSubgamesLimits: build.query({
      query: () => 'v1/sub_games_limits'
    }),

    getBanners: build.query<TBanner[], void>({
      query: () => '/v1/banners',
      transformResponse: adapteBanners
    }),

    getAppSports: build.query<AppSports, void>({
      query: () => 'v1/sports',
      transformResponse: adapteAppSports
    }),

    getKycMethods: build.query<KYCMethod[], void>({
      query: () => '/v1/kyc_methods',
      transformResponse: adapteKYCMethods
    }),

  }),
  overrideExisting: true,
});

export const {
  useGetStatusQuery,
  useGetBanksListQuery,
  useGetBannersQuery,
  useGetCurrenciesQuery,
  useGetWithdrawalMethodsQuery,
  useGetPaymentsMethodsQuery,
  useGetAppSportsQuery,
  useGetKycMethodsQuery
} = appApi;


export default appApi;
