import { useCreatePragmaticSessionQuery } from '@/modules/casino';
import GameScreen from '../game-screen';
import { DOMAIN_NAME } from '@/shared/utils/constants/common';
import { useParams } from 'react-router-dom';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '@/app-config';


const PragmaticPlay = () => {
  const { t, i18n } = useTranslation();
  const { data: appStatuses } = useGetStatusQuery();
  const isPragmaticActive = appStatuses?.pragmatic ?? false;
  const { gameId = '' } = useParams();
  const { data: sessionUrl, isLoading, isError } = useCreatePragmaticSessionQuery({
    gameId,
    lang: i18n.resolvedLanguage ?? DEFAULT_LANG,
    balanceType: 'real',
    lobbyUrl: `${DOMAIN_NAME}/games?filter=Pragmatic+Play`
  }, { skip: !isPragmaticActive });

  if (!isPragmaticActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <GameScreen
      title="Pragmatic Play"
      src={sessionUrl}
      loading={isLoading}
      isError={isError}
      errorMessage={t('common.unknown-error', 'Неизвестная ошибка')}
    />
  );
};

export default PragmaticPlay;
