import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dayjsToUnix } from '@/widgets/settings/utils/datetime-format';
import { DEFAULT_LANG } from '@/app-config';

import type { Dayjs } from 'dayjs';
import type { TResultsFilter } from '@/modules/results/model/types';

const initialFilter: TResultsFilter = {
  sport: 0,
  tournament: 0,
  lang: DEFAULT_LANG,
  date: 0,
};

export const useResultsFilter = () => {
  const { i18n: { resolvedLanguage: userLang } } = useTranslation();
  const [filter, setFilter] = useState({ ...initialFilter, lang: userLang ?? DEFAULT_LANG });

  const handleFilterChange = (newFilter: Partial<TResultsFilter>) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newFilter
    }));
  };

  const handleDateChange = (date: Dayjs | 0) => {
    if (date === 0) {
      handleFilterChange({
        sport: 0,
        tournament: 0,
        date
      });
    } else {
      handleFilterChange({
        sport: 0,
        tournament: 0,
        date: dayjsToUnix(date)
      });
    }
  };

  return {
    filter,
    handleFilterChange,
    handleDateChange,
    userLang: userLang ?? DEFAULT_LANG,
  };
};
