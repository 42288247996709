import { type HandledError, handleQueryError } from '@/shared/utils/helpers/handle-query-error';
import { RegisterErrorEmail } from '../components/register-form/register-form-by-email';
import i18n from '@/shared/i18n/i18n';
import { RegisterErrorTelegram } from '../components/register-form/register-form-by-telegram';
import { dropRefCode } from './ref-code';
import { dropClickId } from './clickid';
import { dropWebId } from './webid';

const errorsTranslates: Record<string, string> = {
  'Invalid name': i18n.t('backend-errors.auth.invalid-name', 'Введите имя и фамилию'),
  'Password should contain at least 8 symbols': 'Минимальный пароль 8 символов'
};

export const handleRegisterErrorEmail = (error?: HandledError): RegisterErrorEmail => {
  const queryError = handleQueryError(error);
  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;
    const errors: RegisterErrorEmail = {};

    if (typeof detail === 'string') {
      switch (detail) {
        case 'User exists':
        case 'User with this email exists':
          errors.email = i18n.t('backend-errors.auth.user-exists', 'Пользователь уже зарегистрирован');
          break;
        case 'Invalid promocode':
        case 'Promocode not found':
          errors.promocode = i18n.t('result-modal.promocode.error.not-found', 'Неверный промокод');
          break;
        case 'Invalid referral link':
          errors.root = detail;
          dropRefCode();
          dropClickId();
          dropWebId();
          break;
        default:
          errors.root = detail;
      }
    }

    if (Array.isArray(detail)) {
      detail.forEach((err) => {

        const location = err.loc?.[1] as keyof NonNullable<RegisterErrorEmail>;
        const message = errorsTranslates[err.msg] || err.msg;

        switch (location) {
          case 'email':
            errors.email = message;
            break;
          case 'name':
            errors.name = message;
            break;
          case 'password':
            errors.password = message;
            break;
          case 'currency':
            errors.currency = message;
            break;
          default:
            errors.root = message;
        }
      });
    }

    return errors;
  }
};

export const handleRegisterErrorTelegram = (error?: HandledError): RegisterErrorTelegram => {
  const queryError = handleQueryError(error);
  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;
    const errors: RegisterErrorTelegram = {};

    if (typeof detail === 'string') {
      switch (detail) {
        case 'User exists':
        case 'User with this telegram nickname exists':
          errors.telegram = i18n.t('backend-errors.auth.user-exists', 'Пользователь уже зарегистрирован');
          break;
        case 'Invalid promocode':
        case 'Promocode not found':
          errors.promocode = i18n.t('result-modal.promocode.error.not-found', 'Неверный промокод');
          break;
        case 'Invalid referral link':
          errors.root = detail;
          dropRefCode();
          dropClickId();
          dropWebId();
          break;
        default:
          errors.root = detail;
      }
    }

    if (Array.isArray(detail)) {
      detail.forEach((err) => {
        const location = err.loc?.[1] as keyof NonNullable<RegisterErrorEmail>;
        const message = errorsTranslates[err.msg] || err.msg;

        switch (location) {
          case 'email':
            errors.telegram = message;
            break;
          case 'name':
            errors.name = message;
            break;
          case 'password':
            errors.password = message;
            break;
          case 'currency':
            errors.currency = message;
            break;
          default:
            errors.root = message;
        }
      });
    }

    return errors;
  }
};
