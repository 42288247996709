import { forwardRef } from 'react';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import DatePicker from 'react-datepicker';
import DatePickerBtn from './datepicker-btn';

import 'react-datepicker/dist/react-datepicker.css';
import './custom-datepicker.scss';

import type { Dayjs } from 'dayjs';
import type { AvailableIcon } from '../icon-svg/icon-svg';

type CustomDatePickerProps = {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  onDateChange?: (date: Dayjs | 0) => void;
  btnClassName?: string;
  variant?: 'primary' | 'secondary' | 'bordered' | 'text';
  isHideValue?: boolean;
  icon?: AvailableIcon;
  isPortal?: boolean;
}

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const {
    selectedDate,
    setSelectedDate,
    onDateChange,
    btnClassName,
    variant = 'secondary',
    isHideValue,
    icon,
    isPortal,
  } = props;

  const CustomInput = forwardRef(
    DatePickerBtn,
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onDateChange?.(dayjsCustom(date));
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      customInput={
        <CustomInput
          className={btnClassName}
          value={selectedDate?.toString()}
          isHideValue={isHideValue}
          onClick={() => { }}
          variant={variant}
          icon={icon}
        />
      }
      dateFormat={'dd/MM/yyyy'}
      locale="ru-RU"
      calendarStartDay={1}
      maxDate={new Date()}
      withPortal={isPortal}
    />
  );
};

export default CustomDatePicker;
