import { DEFAULT_LANG } from '@/app-config';
import { useCreateTVBetSessionQuery } from '@/modules/casino';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { SiteMaintenance, Maintenance } from '@/widgets/site-maintenance';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type StartGameParams = {
  lng?: string;
  clientId?: string;
  token?: string;
  server?: string;
  containerId?: string;
  gameId?: string;
}

const startGame = ({
  lng,
  clientId,
  token,
  server = 'https://frame.fastsport.bet',
  containerId = 'tvbet-container',
  gameId,
}: StartGameParams) => {
  // @ts-ignore
  new FrameBuilder({
    'lng': lng,
    'clientId': clientId,
    'token': token,
    'server': server,
    'containerId': containerId,
    'game_id': gameId ?? false,
  });
};

const loadScript = (onLoad: () => void) => {
  const tvbetScript = document.getElementById('tvbet-script');

  if (!tvbetScript) {
    const script = document.createElement('script');

    script.onload = onLoad;

    script.type = 'text/javascript';
    script.id = 'tvbet-script';
    script.src = 'https://frame.fastsport.bet/assets/frame.js';

    document.head.appendChild(script);
  } else {
    onLoad();
  }
};

const removeScript = () => {
  document.getElementById('tvbet-script')?.remove();
};

const TvBetFastSport = () => {
  const { i18n } = useTranslation();
  const { data: appStatuses } = useGetStatusQuery();
  const isTvbetActive = appStatuses?.tvbet ?? false;
  const { gameId } = useParams();
  const { data } = useCreateTVBetSessionQuery(
    undefined, { skip: !isTvbetActive }
  );

  useEffect(() => {
    if (isTvbetActive && data?.token && data?.clientId) {
      loadScript(() => startGame({
        lng: i18n.resolvedLanguage ?? DEFAULT_LANG,
        clientId: data?.clientId,
        token: data?.token,
        gameId,
      }));
    }


    return removeScript;
  }, [data]);

  if (!isTvbetActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <div>
      <div id="tvbet-container"></div>
    </div>
  );
};

export default TvBetFastSport;
