import { useCreateGoshSessionQuery } from '@/modules/casino/api/casino-api';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import GameScreen from '../game-screen';
import { Maintenance, SiteMaintenance } from '@/widgets/site-maintenance';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { DEFAULT_LANG } from '@/app-config';


const Gosh = () => {
  const { t, i18n } = useTranslation();
  const { data: appStatuses } = useGetStatusQuery();
  const isGoshActive = appStatuses?.gosh ?? false;
  const { gameId = '' } = useParams();
  const { isDesktop } = useResponsive();

  const { data: sessionUrl, isLoading, isError } = useCreateGoshSessionQuery({
    gameId: Number(gameId),
    lang: i18n.resolvedLanguage ?? DEFAULT_LANG,
    lobbyUrl: `${window.location.origin}/games`,
    isDesktop,
  });

  if (!isGoshActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <GameScreen
      title="Gosh"
      src={sessionUrl}
      loading={isLoading}
      isError={isError}
      errorMessage={t('common.unknown-error', 'Неизвестная ошибка')}
    />
  );
};

export default Gosh;
