import type {
  TResultsSportServer,
  TResultsSport,
  TResultsChampServer,
  TResultsChamp,
  TResultsGameServer,
  TResultsGame,
  TResultsGameServerType,
  TResultsGameType,
} from './types';

export const adaptResultsSportList = (res: TResultsSportServer[]): TResultsSport[] => res.map((sport) => ({
  id: sport.Id,
  name: sport.Name,
}));

export const adaptResultsChampList = (res: TResultsChampServer[]): TResultsChamp[] => res.map((champ) => ({
  id: champ.Id,
  name: champ.Name,
}));

export const adaptGameType = (res: TResultsGameServerType): TResultsGameType => ({
  id: res.Id,
  name: res.Name,
  start: res.Start,
  score: res.Score,
});

export const adaptResultsGame = (res: TResultsGameServer): TResultsGame => ({
  id: res.Id,
  name: res.Name,
  start: res.Start,
  score: res.Score,
  ext: res.Ext.map(adaptGameType),
  sportId: res.SportId,
  sportName: res.SportName,
  champId: res.ChampId,
  chanmpName: res.ChanmpName,
});

export const adaptResultsGameList = (res: TResultsGameServer[]): TResultsGame[] => res.map(adaptResultsGame);
