import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import {
  ResultsControlsMobile,
  ResultsFilterMobile,
  useResultsFilter,
} from '@/modules/results';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import Container from '@shared/components/container/container';

import './results-screen-mobile.scss';

const b = bemCn('line-screen-mobile');

const ResultsScreenMobile = () => {
  useScrollToTop();
  const {
    filter,
    handleFilterChange,
    handleDateChange,
  } = useResultsFilter();

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <ResultsControlsMobile
          className={b('controlls')}
          onDateChange={handleDateChange}
        />
        <ResultsFilterMobile
          filter={filter}
          onFilterChange={handleFilterChange}
        />
      </Container>
    </div>
  );
};

export default ResultsScreenMobile;
