import ResultsFilterContext from '../../model/results-filter-context';
import ResultsSportsMobile from './results-sports-mobile';

import './results-filter-mobile.scss';

import type { TResultsFilter } from '../../model/types';

type ResultsFilterMobileProps = {
  filter: TResultsFilter;
  onFilterChange: (newFilter: Partial<TResultsFilter>) => void;
}

const ResultsFilterMobile = ({ filter, onFilterChange }: ResultsFilterMobileProps) => (
  <ResultsFilterContext.Provider value={{ filter, onFilterChange }}>
    <ResultsSportsMobile />
  </ResultsFilterContext.Provider>
);

export default ResultsFilterMobile;
