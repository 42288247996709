import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  adaptResultsSportList,
  adaptResultsChampList,
  adaptResultsGameList,
  adaptResultsGame,
} from '../model/adapter';

import type {
  TGetResultsSportParams,
  TResultsSport,
  TResultsSportsResponse,
  TGetResultsChampParams,
  TResultsChamp,
  TResultsChampsResponse,
  TGetResultsGamesParams,
  TResultsGame,
  TResultsGamesResponse,
  TGetResultsGameParams,
  TResultsGameResponse,
} from '../model/types';

export const resultsApi = createApi({
  reducerPath: 'resultsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://rez.odds24.online/v1/rez',
    prepareHeaders: (headers) => {
      headers.set('Package', 'test_stag_2c15ce1c38e040b22bd70fb5677f4a32');
      return headers;
    },
  }),
  keepUnusedDataFor: 60,
  endpoints: (build) => ({

    getResultsSports: build.query<TResultsSport[], TGetResultsSportParams>({
      query: (params) => `/getsports/${params.lang}/${params.date}`,
      transformResponse: (res: TResultsSportsResponse) => adaptResultsSportList(res.body),
    }),

    getResultsChamps: build.query<TResultsChamp[], TGetResultsChampParams>({
      query: (params) => `/getchamps/${params.lang}/${params.date}/${params.sportId}`,
      transformResponse: (res: TResultsChampsResponse) => adaptResultsChampList(res.body),
    }),

    getResultsGameList: build.query<TResultsGame[], TGetResultsGamesParams>({
      query: (params) => `/getgames/${params.lang}/${params.date}/${params.sportId}/${params.champId}`,
      transformResponse: (res: TResultsGamesResponse) => adaptResultsGameList(res.body),
    }),

    getResultsGame: build.query<TResultsGame, TGetResultsGameParams>({
      query: (params) => `/getgame/${params.lang}/${params.date}/${params.gameId}`,
      transformResponse: (res: TResultsGameResponse) => adaptResultsGame(res.body),
    }),

  }),
});

export const {
  useGetResultsSportsQuery,
  useGetResultsChampsQuery,
  useGetResultsGameListQuery,
  useGetResultsGameQuery,
} = resultsApi;
