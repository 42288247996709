import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './file-input.scss';
import { ChangeEvent, FC, InputHTMLAttributes, useState } from 'react';

const b = bemCn('file-input');

export type FileInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  id?: string;
  error?: string;
  disabled?: boolean;
  inverted?: boolean;
  onChange?: (file: File | null | undefined) => void;
  status?: 'error' | 'success';
}

const FileInput: FC<FileInputProps> = ({
  label = 'Загрузить файл',
  id = 'fileInput',
  onChange,
  error,
  disabled,
  inverted,
  status,
  className,
  ...inputProps
}) => {
  const [fileName, setFileName] = useState<string>('Файл не выбран');

  const isError = status === 'error' && error;

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0]; // Получаем первый выбранный файл
    if (file) {
      setFileName(file.name); // Устанавливаем имя файла

    } else {
      setFileName('Файл не выбран');
    }
    onChange?.(file);
  };

  return (
    <div className={b({ inverted, disabled }, className)}>
      <div className={b('wrapper')}>
        <label className={b('label')}
          htmlFor={id}
        >
          {label}
        </label>
        <input
          {...inputProps}
          className='visually-hidden'
          type="file"
          id={id}
          disabled={disabled}
          onChange={handleFileChange}
        />
        <p className={b('file-name')}>
          {fileName}
        </p>
      </div>
      {isError && <span className={b('error')}>{error}</span>}
    </div>
  );
};

export default FileInput;
