import CasinoProviderCard from '@/modules/casino/components/casino-provider-card/casino-provider-card';
import { providerLink, providerLogo } from '@/modules/casino/components/casino-providers-section/casino-providers-section';
import { useThemeContext } from '@/modules/theme/model/theme-context';
import { getUserData, selectUserCasinoBonuses, selectUserFreespinsCount } from '@/modules/user/store/selectors';
import BalanceInfo from '@/shared/components/balance-info/balance-info';
import { useAppSelector } from '@/shared/hooks';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { getAmountString, roundNumber } from '@/shared/utils/helpers/common';
import { useTranslation } from 'react-i18next';

import './casino-bonuses-section.scss';
import React from 'react';

const b = bemCn('casino-bonuses-section');

const CasinoBonusesSection = () => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const casinoBonuses = useAppSelector(selectUserCasinoBonuses);
  const userData = useAppSelector(getUserData);
  const totalSpinsCount = useAppSelector(selectUserFreespinsCount);

  const {
    currency,
    cashbackCasino,
    casinoBonus,
    wagerScaleCurrentAmount,
    wagerScaleTargetAmount,
  } = userData.wallet;
  const isLightTheme = theme === 'light-blue';

  const cashbackCasinoBalance = getAmountString(roundNumber(cashbackCasino).toFixed(2), currency);
  const casinoBonusBalance = getAmountString(roundNumber(casinoBonus).toFixed(2), currency);
  const wagerBalance = getAmountString(`${wagerScaleCurrentAmount}/${wagerScaleTargetAmount}`, currency);

  const providersList = casinoBonuses.map((item, i) => ({
    key: i,
    logoUrl: providerLogo[item.provider.toLowerCase()],
    link: providerLink[item.provider.toLowerCase()],
    freespins: item.fsAmount,
  }));

  if (totalSpinsCount <= 0) {
    return null;
  }

  return (
    <div className={b()}>
      <div className={b('providers-list', 'no-scrollbar')}>
        {providersList.map((item) => (
          <CasinoProviderCard className={b('providers-item')}
            key={item.key}
            logoUrl={item.logoUrl}
            link={item.link}
            freespins={item.freespins}
          />
        ))}
      </div>

      <div className={b('balances')}>
        <BalanceInfo
          className={b('balance')}
          title={t('wallets.casino-bonuses', 'Кэшбэк казино')}
          value={cashbackCasinoBalance}
          icon='coin'
          iconColor='pink'
          style={isLightTheme ? 'light' : undefined}
        />
        <BalanceInfo
          className={b('balance')}
          title="Casino Bonus"
          value={casinoBonusBalance}
          icon='coin'
          iconColor='orange'
          style={isLightTheme ? 'light' : undefined}
        />
        <BalanceInfo
          className={b('balance')}
          title={t('wallets.wager', 'Отыгрыш бонуса')}
          value={wagerBalance}
          icon='star'
          iconColor='blue'
          style={isLightTheme ? 'light' : undefined}
        />
      </div>
    </div>
  );
};

export default React.memo(CasinoBonusesSection);
