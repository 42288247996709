import { useTranslation } from 'react-i18next';
import ResultModal from '@/shared/components/result-modal/result-modal';

type TelegramAddSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const TelegramAddSuccessModal = ({
  isOpen,
  onClose,
}: TelegramAddSuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.confirmation.telegram.add-success', 'Telegram добавлен')}
      description={t('result-modal.confirmation.telegram.add-success-description', 'Telegram никнайм успешно добавлен!')}
      status='success'
    />
  );
};

export default TelegramAddSuccessModal;
