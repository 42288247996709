
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { getAmountString, roundNumber } from '@shared/utils/helpers/common';
import BalanceInfo from '@shared/components/balance-info/balance-info';
// import ButtonIcon from '@shared/components/button-icon/button-icon';
import Button from '@shared/components/button/button';
import Dropdown from '@shared/components/dropdown/dropdown';
import {
  useAppSelector,
  // useDispatchedActions
} from '@shared/hooks';
import { getUserData } from '@/modules/user/store/selectors';
import { getBalanceHidden } from '@/shared/store/app/selectors';
import { useTranslation } from 'react-i18next';
import './user-wallet-dropdown.scss';

type UserWalletDropdownProps = {
  className?: string;
};

const b = bemCn('user-wallet-dropdown');

const UserWalletDropdown = ({ className }: UserWalletDropdownProps) => {
  const { t } = useTranslation();
  const {
    wallet: {
      amount: userBalance,
      currency,
      bonusAmount,
      cashbackCasino,
      cashbackLine,
      casinoBonus,
      wagerScaleCurrentAmount,
      wagerScaleTargetAmount,
    },
  } = useAppSelector(getUserData);
  const isBalanceHidden = useAppSelector(getBalanceHidden);

  // const { changeAppModal } = useDispatchedActions();

  // const handleManageClick = () => changeAppModal('wallet-manage');

  const mainBalance = getAmountString(roundNumber(userBalance).toFixed(2), currency, isBalanceHidden);
  const bonusBalance = getAmountString(roundNumber(bonusAmount).toFixed(2), currency);
  const cashbackLineBalance = getAmountString(roundNumber(cashbackLine).toFixed(2), currency);
  const cashbackCasinoBalance = getAmountString(roundNumber(cashbackCasino).toFixed(2), currency);
  const casinoBonusBalance = getAmountString(roundNumber(casinoBonus).toFixed(2), currency);
  const wagerBalance = getAmountString(`${wagerScaleCurrentAmount}/${wagerScaleTargetAmount}`, currency);


  return (
    <Dropdown
      className={b(null)}
      classNameContent={b('content')}
      renderTriger={({ toggel }) => (
        <Button className={b('button', className)}
          onClick={toggel}
          variant="secondary"
          leftIcon='coin-colored'
        >
          {mainBalance}
        </Button>
      )}
      renderContent={() => (
        <div className={b('info')}>
          <div className={b('info-header')}>
            <div className={b('main-balance')}>
              <div className={b('main-balance-wrapper')}>
                <p className={b('main-balance-title')}>
                  {t('wallets.main-wallet', 'Основной счет')}
                </p>
                <p className={b('main-balance-value')}>
                  {mainBalance}
                </p>
              </div>

              {/* Скрыл, потому что кажется бесполеным, кнопки вывода и пополнения доступны в других более очевидных местах */}

              {/* <Dropdown
                className={b('main-balance-menu')}
                classNameContent={b('main-balance-menu-wrapper')}
                renderTriger={({ toggel }) => (
                  <ButtonIcon
                    className={b('main-balance-btn')}
                    icon="dots-menu"
                    variant='text'
                    onClick={toggel}
                  />
                )}

                renderContent={() => (
                  <div className={b('main-balance-menu-list')}>
                    <button className={b('main-balance-menu-item')}>
                      Вывести
                    </button>
                    <button className={b('main-balance-menu-item')}>
                      Пополнить
                    </button>
                  </div>
                )}
              /> */}
            </div>
            {/* <Button className={b('manage-btn')} onClick={handleManageClick}>
              {t('profile.nav.wallets-manage', 'Управление счетами')}
            </Button> */}
          </div>
          <BalanceInfo
            className={b('balance')}
            title={t('wallets.bonus-wallet', 'Бонусный счет')}
            value={bonusBalance}
            icon='piggy-bank'
            iconColor='yellow'
            style='light'
          />
          <BalanceInfo
            className={b('balance')}
            title={t('wallets.casino-bonuses', 'Кэшбэк казино')}
            value={cashbackCasinoBalance}
            icon='coin'
            iconColor='pink'
            style='light'
          />
          <BalanceInfo
            className={b('balance')}
            title={t('wallets.sport-bonuses', 'Кэшбэк ставки')}
            value={cashbackLineBalance}
            icon='cup'
            iconColor='green'
            style='light'
          />
          <BalanceInfo
            className={b('balance')}
            title="Casino Bonus"
            value={casinoBonusBalance}
            icon='coin'
            iconColor='orange'
            style='light'
          />
          <BalanceInfo
            className={b('balance')}
            title={t('wallets.wager', 'Отыгрыш бонуса')}
            value={wagerBalance}
            icon='star'
            iconColor='blue'
            style='light'
          />
        </div>
      )}
    />
  );
};

export default UserWalletDropdown;
