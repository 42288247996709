import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useResultsFilterContext } from '../../model/results-filter-context';
import {
  useGetResultsChampsQuery,
} from '../../api/results-api';
import LoaderCenter from '@/shared/components/loader-center/loader-center';

import './results-filter-desktop.scss';

import type { TResultsChamp } from '../../model/types';

const b = bemCn('results-filter-desktop');

type TournamentItemProps = {
  tournament: TResultsChamp;
};

const TournamentItem = ({ tournament }: TournamentItemProps) => {
  const { filter, onFilterChange } = useResultsFilterContext();
  const isActive = tournament.id === filter.tournament;

  const onTournamentChange = () => {
    onFilterChange({
      tournament: tournament.id,
    });
  };

  return (
    <div className={b('tournament-item')}>
      <button className={b('tournament-btn', { active: isActive })}
        type="button"
        onClick={onTournamentChange}
      >
        {tournament.name}
      </button>
    </div>
  );
};

const ResultsTournamentsDesktop = () => {
  const { filter } = useResultsFilterContext();
  const { data: tournamenstData, isLoading } = useGetResultsChampsQuery({
    lang: filter.lang,
    date: filter.date,
    sportId: filter.sport,
  });

  if (isLoading) {
    return <LoaderCenter />;
  }

  return (
    <div className={b('tournament-list')}>
      {tournamenstData?.map((tournament) => (
        <TournamentItem key={tournament.id} tournament={tournament} />
      ))}
    </div>
  );

};

export default ResultsTournamentsDesktop;
