import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { getIsAuth } from '@/modules/auth';
import { useAppSelector } from '@/shared/hooks';
import { useResultsFilterContext } from '../../model/results-filter-context';
import {
  useGetResultsSportsQuery,
} from '../../api/results-api';
import { getSportIconName } from '@/shared/utils/helpers/common';
import Loader from '@/shared/components/loader/loader';
import Button from '@/shared/components/button/button';
import IconSvg from '@shared/components/icon-svg/icon-svg';
import GradientIcon, { GRADIENT_COLORS } from '@shared/components/gradient-icon/gradient-icon';
import ResultsTournamentsDesktop from './results-tournaments-desktop';
import ResultsSportControls from './results-sport-controls';

import './results-filter-desktop.scss';

import type { TResultsSport } from '../../model/types';
import type { ColorName as GradientColor } from '@shared/components/gradient-icon/gradient-icon';

const b = bemCn('results-filter-desktop');

const FavoritesBtn = () => {
  const { t } = useTranslation();
  const isAuth = useAppSelector(getIsAuth);
  const count = 0;

  if (!isAuth || count <= 0) {
    return null;
  }

  return (
    <div>
      <button className={b('sport-btn')}
        type='button'
      >
        <GradientIcon className={b('sport-icon')}
          iconName="heart"
          color="orange"
        />
        <span className={b('sport-name')}>
          {t('common.favorite', 'Избранное')}
        </span>
        <span className={b('sport-counter')}>{count}</span>
      </button>
    </div>
  );
};

type SportBtnProps = {
  sport: TResultsSport;
  active: boolean;
  color?: GradientColor;
  disabled?: boolean;
}

const SportBtn = (props: SportBtnProps) => {
  const { sport, active, color, disabled } = props;
  const { onFilterChange } = useResultsFilterContext();
  return (
    <button className={b('sport-btn', { active })}
      disabled={disabled}
      type="button"
      onClick={() => onFilterChange({
        sport: active ? 0 : sport.id,
        tournament: 0,
      })}
    >
      <GradientIcon className={b('sport-icon')}
        iconName={getSportIconName(sport.id.toString())}
        color={color ?? 'purple'}
      />
      <span className={b('sport-name')}>{sport.name}</span>
      <IconSvg className={b('sport-toggle-icon')} name="chevron-down" />
    </button>
  );
};

type SportItemProps = {
  sport: TResultsSport;
  index: number;
  disabled?: boolean;
}

const SportItem = (props: SportItemProps) => {
  const {
    sport,
    index,
    disabled,
  } = props;
  const { filter } = useResultsFilterContext();
  const isActive = sport.id === filter.sport;
  return (
    <div className={b('sport-item')}>
      <SportBtn
        sport={sport}
        active={isActive}
        color={GRADIENT_COLORS[index % GRADIENT_COLORS.length]}
        disabled={disabled}
      />
      {isActive && !disabled && (
        <ResultsTournamentsDesktop />
      )}
    </div>
  );
};

const ResultsSportsDesktop = () => {
  const [shownCount, setShownCount] = useState(20);
  const { filter } = useResultsFilterContext();
  const { data: sportsData, isFetching: isLoading } = useGetResultsSportsQuery({ lang: filter.lang, date: filter.date });
  const sportsCount = sportsData?.length ?? 0;

  const handleShowMoreClick = () => {
    const nextCount = shownCount + 20;
    setShownCount(Math.min(nextCount, sportsCount));
  };

  return (
    <div className={b()}>
      <ResultsSportControls />
      <FavoritesBtn />
      {isLoading && <Loader className={b('loader')} />}
      {!isLoading && sportsData?.slice(0, shownCount).map((sport, index) => (
        <SportItem key={sport.id} sport={sport} index={index} />
      ))}

      {!isLoading && shownCount < sportsCount && (
        <Button className={b('show-more')} variant="text" onClick={handleShowMoreClick}>
          <Trans i18nKey="common.show-more">
            Показать ещё
          </Trans>
        </Button>
      )}
    </div>
  );
};

export default ResultsSportsDesktop;
