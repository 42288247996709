import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import Button from '@/shared/components/button/button';

import './results-controls-desktop.scss';

import type { PropsWithCN } from '@shared/types/common';

const b = bemCn('resutls-controls-desktop');

type ResultsControlsMobileProps = PropsWithCN;

const ResultsControlsDesktop = ({
  className,
}: ResultsControlsMobileProps) => {
  const { t } = useTranslation();

  return (
    <div className={b(null, className)}>
      <div className={b('wrapper')}>
        <Button className={b('btn-text')}
          type="button"
          variant="primary"
        >
          {t('results.today', 'Сегодня')}
        </Button>
        <Button className={b('btn-text')}
          type="button"
          variant="secondary"
        >
          {t('results.yesterday', 'Вчера')}
        </Button>
        <Button className={b('btn-text')}
          type="button"
          variant="secondary"
        >
          {t('results.day-before-yesterday', 'Позавчера')}
        </Button>
        <ButtonIcon className={b('btn')}
          icon="calendar"
          variant='secondary'
        />
      </div>
    </div>
  );
};

export default ResultsControlsDesktop;
