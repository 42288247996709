import { bemCn } from '@shared/utils/helpers/bem-cn';
import GradientIcon, { GRADIENT_COLORS } from '@shared/components/gradient-icon/gradient-icon';
import { getSportIconName } from '@/shared/utils/helpers/common';
import { useResultsFilterContext } from '../../model/results-filter-context';
import {
  useGetResultsSportsQuery,
} from '../../api/results-api';
import ResultsTournamentsMobile from './results-tournaments-mobile';
import Loader from '@/shared/components/loader/loader';

import './results-filter-mobile.scss';

import type { TResultsSport } from '../../model/types';
import type { ColorName as GradientColor } from '@shared/components/gradient-icon/gradient-icon';

const b = bemCn('results-filter-mobile');

type SportBtnProps = {
  sport: TResultsSport;
  active: boolean;
  color?: GradientColor;
  disabled?: boolean;
}

const SportBtn = (props: SportBtnProps) => {
  const { sport, active, color, disabled } = props;
  const { onFilterChange } = useResultsFilterContext();
  return (
    <button className={b('sport-btn', { active })}
      disabled={disabled}
      type="button"
      onClick={() => onFilterChange({
        sport: active ? 0 : sport.id,
        tournament: 0,
      })}
    >
      <GradientIcon className={b('sport-icon')}
        iconName={getSportIconName(sport.id.toString())}
        color={color ?? 'purple'}
      />
      <span className={b('sport-name')}>{sport.name}</span>
    </button>
  );
};

type SportItemProps = {
  sport: TResultsSport;
  index: number;
  disabled?: boolean;
}

const SportItem = (props: SportItemProps) => {
  const {
    sport,
    index,
    disabled,
  } = props;
  const { filter } = useResultsFilterContext();
  const isActive = sport.id === filter.sport;
  return (
    <div className={b('sport-item')}>
      <SportBtn
        sport={sport}
        active={isActive}
        color={GRADIENT_COLORS[index % GRADIENT_COLORS.length]}
        disabled={disabled}
      />
      {isActive && !disabled && (
        <ResultsTournamentsMobile />
      )}
    </div>
  );
};

const ItemsLoader = () => (
  <div className={b('loader-wrapper')}>
    <Loader className={b('loader')} />
    {new Array(10).fill(null).map((_, i) => (
      <SportItem
        key={i}
        index={i}
        sport={{
          id: 0,
          name: '',
        }}
        disabled
      />
    ))}
  </div>
);

const ResultsSportsMobile = () => {
  const { filter } = useResultsFilterContext();
  const { data: sportsData, isLoading } = useGetResultsSportsQuery({ lang: filter.lang, date: filter.date });

  return (
    <div className={b()}>
      {isLoading && <ItemsLoader />}
      {!isLoading && sportsData?.map((sport, index) => (
        <SportItem key={sport.id} sport={sport} index={index} />
      ))}
    </div>
  );
};

export default ResultsSportsMobile;
