import { bemCn } from '@shared/utils/helpers/bem-cn';
import casinoProviderLogo from '@assets/casino-provider-logo.png?url';
import './casino-provider-card.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const b = bemCn('casino-provider-card');

type CasinoProviderCardProps = {
  className?: string;
  logoUrl?: string;
  link?: string;
  name?: string;
  freespins?: number;
}

const CasinoProviderCard = ({
  className,
  logoUrl = casinoProviderLogo,
  name = 'Casino Provider',
  link = '',
  freespins
}: CasinoProviderCardProps) => {
  const { t } = useTranslation();
  return (
    <div className={b(null, className)}>
      <Link className={b('link')} to={link} state={{ scrollToView: true }}>
        {freespins && (
          <p className={b('freespins')}>
            {freespins} {t('casino-widget.free-spins-short', 'ФС')}
          </p>
        )}
        <img className={b('logo-img')} src={logoUrl} alt={name} loading='lazy' />
      </Link>
    </div>
  );
};

export default CasinoProviderCard;
