import { useNavigate } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useResultsFilterContext } from '../../model/results-filter-context';
import {
  useGetResultsChampsQuery,
} from '../../api/results-api';
import LoaderCenter from '@/shared/components/loader-center/loader-center';

import './results-filter-mobile.scss';

import type { TResultsChamp } from '../../model/types';

const b = bemCn('results-filter-mobile');

type TournamentItemProps = {
  tournament: TResultsChamp;
};

const TournamentItem = ({ tournament }: TournamentItemProps) => {
  const navigate = useNavigate();
  const { filter } = useResultsFilterContext();

  return (
    <div className={b('tournament-item')}>
      <button className={b('tournament-btn')}
        type="button"
        onClick={() => navigate(`/results/${filter.lang}/${filter.date}/${filter.sport}/${tournament.id}`)}
      >
        {tournament.name}
      </button>
    </div>
  );
};

const ResultsTournamentsMobile = () => {
  const { filter } = useResultsFilterContext();
  const { data: tournamenstData, isLoading } = useGetResultsChampsQuery({
    lang: filter.lang,
    date: filter.date,
    sportId: filter.sport,
  });

  if (isLoading) {
    return <LoaderCenter />;
  }

  return tournamenstData?.map((tournament) => (
    <TournamentItem key={tournament.id} tournament={tournament} />
  ));

};

export default ResultsTournamentsMobile;
