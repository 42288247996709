import { createContext, useContext } from 'react';

import type { Dayjs } from 'dayjs';
import type { TResultsFilter } from './types';

interface IFilterContext {
  filter: TResultsFilter;
  onFilterChange: (newFilter: Partial<TResultsFilter>) => void;
  onDateChange?: (date: Dayjs | 0) => void;
}

const ResultsFilterContext = createContext<IFilterContext | undefined>(undefined);

const useResultsFilterContext = () => {
  const filterContext = useContext(ResultsFilterContext);

  if (!filterContext) {
    throw new Error('FilterContext not defined');
  }

  return filterContext;
};

export { useResultsFilterContext };

export default ResultsFilterContext;
