import logotype from '@assets/logotype.svg';
import logoLightYellow from '@assets/logotype-white-yellow.svg';
import { preloadImages } from './shared/utils/helpers/preload';

export const TOKENT_KEY_NAME = 'boompari-token';
export const DEFAULT_CURRENCY = 'UAH';
export const DEFAULT_TIMEZONE = 3;
export const DEFAULT_LANG = 'en';
export const AVAILABLE_LANGS = ['ru', 'uk', 'en', 'kk', 'az', 'uz', 'da'];
export const ONLY_CURRENCY: string | null = null;
export const EXCLUDED_CURRENCY: string[] | null = null;
export const AVAILABLE_THEMES = ['dark-purple', 'blue', 'dark-blue', 'light-blue'];
export const DEFAULT_THEME = 'dark-purple';
export const COMPANY_NAME = 'Boompari';
export const SUPPORT_LINK = 'https://t.me/boompari_support';
export const SOCIAL_LINK_TG = 'https://t.me/boompari';
export const SOCIAL_LINK_INST = '';
export const SOCIAL_LINK_VK = '';
export const LOGO_BOOMAKER_RATINGS = 'https://bookmaker-ratings.com.ua/ru/review/boompari/';
export const ANDROID_APP = 'https://drive.google.com/file/d/1zJEBgwf-Wjc5WIzN82LQ4ErBpmUG9X4E/view?usp=sharing';
export const EXCLUDE_CASINO_CATEGORY = ['gameslobby', 'roshambo', 'arcade', 'moon', 'jetx'];
export const DEFAULT_LOGOTYPE = logotype;
export const MainLogotype = {
  DARK_PURPLE: logotype,
  BLUE: logoLightYellow,
  DARK_BLUE: logotype,
  LIGHT_BLUE: logotype,
};
export const CouponLogotype = {
  DARK_PURPLE: logotype,
  BLUE: logoLightYellow,
  DARK_BLUE: logotype,
  LIGHT_BLUE: logotype,
};

export const AppFeature = {
  DIGITAIN_GAMES: false,
  BONUS_AND_PROMO: true,
  REGISTRATION_TELEGRAM: false,
  REGISTRATION_PHONE: false,
  KYC_VERIFICATION: true,
  ADAPTIVE_BANNERS: true,
};

preloadImages([logotype, logoLightYellow]);
