import { useTranslation } from 'react-i18next';
import ResultModal from '@/shared/components/result-modal/result-modal';

type TelegramChangedSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const TelegramChangedSuccessModal = ({
  isOpen,
  onClose,
}: TelegramChangedSuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <ResultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('result-modal.confirmation.telegram.changed-success', 'Telegram изменён')}
      description={t('result-modal.confirmation.telegram.changed-success-description', 'Telegram никнайм успешно изменён!')}
      status='success'
    />
  );
};

export default TelegramChangedSuccessModal;
