import { useTranslation } from 'react-i18next';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import Container from '@shared/components/container/container';
import { useScrollToTop } from '../../shared/hooks/use-scrolltotop';
import { useGetResultsGameListQuery } from '@/modules/results/api/results-api';
import { useContext } from 'react';
import { ResultsLayoutContext } from '@/modules/results';
import LoaderCenter from '@/shared/components/loader-center/loader-center';
import {
  // ResultsControlsDesktop,
  ResultsGameDesktop,
} from '@/modules/results';

import './results-games-screen-desktop.scss';

import type { TGetResultsGamesParams } from '@/modules/results/model/types';

const b = bemCn('results-games-screen-desktop');

const ResultsGamesScreenDesktop = () => {
  const { t } = useTranslation();
  useScrollToTop();
  const { filter } = useContext(ResultsLayoutContext);

  const params: TGetResultsGamesParams = {
    lang: filter.lang,
    date: filter.date,
    sportId: filter.sport === 0 ? 1 : filter.sport,
    champId: filter.tournament,
  };

  const { data: gamesData, isFetching: isLoading } = useGetResultsGameListQuery(params);

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <div className={b('head')}>
          <h1 className={b('title')}>
            {t('main-nav.results', 'Результаты')}
          </h1>
          {/* <ResultsControlsDesktop className={b('controlls')} /> */}
        </div>

        {isLoading && <LoaderCenter />}
        {!isLoading && gamesData?.map((game) => (
          <ResultsGameDesktop
            key={game.id}
            sportId={filter.sport.toString()}
            game={game}
          />
        ))}
      </Container>
    </div>
  );
};

export default ResultsGamesScreenDesktop;
