import { bemCn } from '@/shared/utils/helpers/bem-cn';
import AppModal from '@/shared/components/app-modal/app-modal';
import { useTranslation } from 'react-i18next';
import { useGetKycMethodsQuery } from '@/shared/store/app/app-api';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { FC, useState } from 'react';
import { KYCMethod } from '@/shared/store/app/types';
import KYCModal from '../kyc-modal/kyc-modal';

import './kyc-methods-modal.scss';

const b = bemCn('kyc-methods-modal');

type KYCMethodsModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const KYCMethodsModal: FC<KYCMethodsModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [selectedMethod, setSelectedMethod] = useState<KYCMethod | null>(null);
  const { data: items = [], isLoading } = useGetKycMethodsQuery();
  const isListEmpty = items.length <= 0;

  return (
    <AppModal className={b()}
      isOpen={isOpen}
      onClose={onClose}
      title={t('kyc-methods-modal.title', 'Kyc верификация')}
      description={t('kyc-methods-modal.pass', 'Пройти Kyc верификацию')}
      classNameTitle={b('title')}
      classNameDesc={b('desc')}
    >
      <div className={b('list')}>
        {!isLoading && isListEmpty && (
          <p className={b('empty')}>
            Нет доступных методов
          </p>
        )}
        {items.map((item) => (
          <button className={b('item')} key={item.id}
            onClick={() => setSelectedMethod(item)}
          >
            <span className={b('item-title')}>{item.name}</span>
            <IconSvg className={b('item-icon')} />
          </button>
        ))}
        {/* {methods.map((method) => (
          <PaymentMethod className={b('item')}
            key={method.id}
            title={method.title}
            icons={method.icons}
            onClick={() => handleMethodClick(method)}
          />
        ))} */}
      </div>
      {/* <WithdrawalModal
        isOpen={activeModal === 'withdrawal-modal'}
        onClose={handleModalClose}
        method={selectedMethod}
      /> */}

      {/* <WithdrawalSuccessModal
        isOpen={activeModal === 'withdrawal-success'}
        onClose={handleModalClose}
      /> */}

      <KYCModal isOpen={selectedMethod !== null}
        onClose={() => setSelectedMethod(null)}
        method={selectedMethod}
      />
    </AppModal>
  );
};

export default KYCMethodsModal;
