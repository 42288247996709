import i18n from '@/shared/i18n/i18n';

import { IChangeTelegramError } from '@/modules/user/store/types';
import { type HandledError, handleQueryError } from '@/shared/utils/helpers/handle-query-error';

export const handleChangeTelegramError = (error?: HandledError): IChangeTelegramError | undefined => {
  const queryError = handleQueryError(error);

  if (queryError) {
    if (typeof queryError === 'string') {
      return {
        root: queryError
      };
    }

    const { detail } = queryError;

    if (typeof detail === 'string') {
      const errors: IChangeTelegramError = {};
      switch (detail) {
        case 'User with this telegram nickname already exists':
          errors.telegram = i18n.t('settings.errors.tg-exists');
          break;
        case 'Telegram nickname should not contain spaces':
          errors.telegram = i18n.t('settings.errors.tg-not-contain-spaces');
          break;
        default:
          errors.root = detail;
      }

      return errors;
    }

    if (Array.isArray(detail)) {
      const errors: IChangeTelegramError = {};
      detail.forEach((err) => {
        // const location = err.loc?.[1];
        const message = err.msg;
        switch (message) {
          case 'User with this telegram nickname already exists':
            errors.telegram = i18n.t('settings.errors.tg-exists');
            break;
          case 'Telegram nickname should not contain spaces':
            errors.telegram = i18n.t('settings.errors.tg-not-contain-spaces');
            break;
          default:
            errors.root = message;
        }
      });

      return errors;
    }

    return {
      root: 'Unknown error',
    };
  }
};
