import { bemCn } from '@/shared/utils/helpers/bem-cn';
import PasswordChange from '../password-change/password-change';
import TelegramChange from '../telegram-change/telegram-change';
import HideBalance from '../hide-balance/hide-balance';
import TimezoneChange from '../timezone-change/timezone-change';
import ThemeChange from '../theme-change/theme-change';
import TelegramNotifications from '../telegram-notifications/telegram-notifications';
import { FC } from 'react';

import './user-settings.scss';

const b = bemCn('user-settings');

type UserSettingsProps = {
  className?: string;
  inverted?: boolean;
}

const UserSettings: FC<UserSettingsProps> = ({ className, inverted }) => (
  <div className={b({ inverted }, className)}>
    <HideBalance className={b('section', { inverted })}
      inverted={inverted}
    />
    <PasswordChange className={b('section', { inverted })}
      inverted={inverted}
    />
    <TelegramChange className={b('section', { inverted })}
      inverted={inverted}
    />
    <TelegramNotifications className={b('section', { inverted })}
      inverted={inverted}
    />
    <TimezoneChange className={b('section', { inverted })}
      inverted={inverted}
    />
    <ThemeChange className={b('section', { inverted })}
      inverted={inverted}
    />
  </div>
);

export default UserSettings;
